<template>
  <div>
    <x-card v-if="hasPerm('sysDownloadLog:page')">
      <div slot="content" class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="4" :sm="24">
              <a-form-item label="用户">
                <a-select v-model="queryParam.userId" allow-clear placeholder="请选择用户">
                  <!-- <a-select-option v-for="(item, index) in visTypeDict" :key="index" :value="item.code"
                    >{{ item.value }}
                  </a-select-option> -->
                  <a-select-option v-for="(item, index) in userslist" :key="index" :value="item.id">{{
                    item.name
                  }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="文件名称">
                <a-input v-model="queryParam.fileOriginName" allow-clear placeholder="请输入文件名称" />
              </a-form-item>
            </a-col>
            <template v-if="1">
              <a-col :md="8" :sm="24">
                <a-form-item label="下载时间">
                  <a-range-picker
                    style="width:100%;"
                    v-model="queryParam.dates"
                    :show-time="{
                      hideDisabledOptions: true,
                      defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')]
                    }"
                    format="YYYY-MM-DD HH:mm:ss"
                  />
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="4" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                <a-button style="margin-left: 8px" @click="reset">重置</a-button>
                <!-- <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a> -->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
    </x-card>
    <a-card :bordered="false">
      <s-table
        ref="table"
        :columns="columns"
        :data="loadData"
        :alert="true"
        :rowKey="record => record.id"
      > 
        <s-column title="序号" dataIndex="index" align="center" :width="55">
          <template slot="customRender" slot-scope="text, record, index">
            {{ index + 1 }}
          </template>
        </s-column>
        <span slot="fileOriginName" slot-scope="text">
          {{ text }}
        </span>
        <span slot="fileSuffix" slot-scope="text">
          {{ text }}
        </span>
        <span slot="userName" slot-scope="text">
          {{ text }}
        </span>
        <span slot="createdTime" slot-scope="text">
          {{ text }}
        </span>
        <!-- <span slot="name" slot-scope="text">
          <ellipsis :length="10" tooltip>{{ text }}</ellipsis>
        </span>
        <span slot="visTime" slot-scope="text">
          <ellipsis :length="10" tooltip>{{ text }}</ellipsis>
        </span>
        <span slot="visType" slot-scope="text">
          {{ visTypeFilter(text) }}
        </span>
        <span slot="success" slot-scope="text">
          {{ successFilter(text) }}
        </span>
        -->
      </s-table>
      <details-vislog ref="detailsVislog" />
    </a-card>
  </div>
</template>
<script>
import { STable, Ellipsis, XCard } from '@/components'
import { sysVisLogPage, sysVisLogDelete } from '@/api/modular/system/logManage'
import detailsVislog from './details'
import { sysEnumDataList } from '@/api/modular/system/enumManage'
import moment from 'moment'
import { _downloadpage } from '@/api/modular/projectManage/system/filesLog.js'
import { getUserList } from '@/api/modular/system/userManage'
export default {
  components: {
    XCard,
    STable,
    Ellipsis,
    detailsVislog
  },
  data() {
    return {
      userslist: [],
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          width: 50,
          align: 'center',
          customRender: (text, record, index) => index + 1
        },
        {
          title: '文件名称',
          dataIndex: 'fileOriginName',
          scopedSlots: {
            customRender: 'fileOriginName'
          }
        },
        {
          title: '文件类型',
          dataIndex: 'fileSuffix',
          scopedSlots: {
            customRender: 'fileSuffix'
          }
        },
        {
          title: '用户',
          dataIndex: 'userName',
          scopedSlots: {
            customRender: 'userName'
          }
        },
        {
          title: '下载时间',
          dataIndex: 'createdTime',
          scopedSlots: {
            customRender: 'createdTime'
          }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return _downloadpage(Object.assign(parameter, this.switchingDate())).then(res => {
          return res.data
        })
      },
      selectedRowKeys: [],
      selectedRows: [],
      defaultExpandedKeys: [],
      visTypeDict: [],
      successDict: []
    }
  },
  /**
   * 相当于html的onload方法，进来初始化
   */
  created() {
    this.sysEnumDataList()
  },
  methods: {
    moment,
    reset() {
      this.queryParam = {}
      this.$refs.table.refresh(true)
    },

    visTypeFilter(visType) {
      // eslint-disable-next-line eqeqeq
      const values = this.visTypeDict.filter(item => item.code == visType)
      if (values.length > 0) {
        return values[0].value
      }
    },
    successFilter(success) {
      // eslint-disable-next-line eqeqeq
      const values = this.successDict.filter(item => item.code == success)
      if (values.length > 0) {
        return values[0].value
      }
    },
    /**
     * 获取枚举数据
     */
    sysEnumDataList() {
      sysEnumDataList({
        enumName: 'LoginType'
      }).then(res => {
        this.visTypeDict = res.data
      })
      sysEnumDataList({
        enumName: 'YesOrNot'
      }).then(res => {
        this.successDict = res.data
      })
      // 查找用户列表
      getUserList().then(res => {
        if (res.success) {
          this.userslist = res.data
          const mockData = []
          res.data.forEach(item => {
            const data = {
              key: item.id.toString(),
              title: item.name
            }
            mockData.push(data)
          })
          // this.userslist = mockData
          this.userslist1 = mockData
        }
      })
    },
    /**
     * 查询参数组装
     */
    switchingDate() {
      const dates = this.queryParam.dates
      if (dates != null) {
        this.queryParam.startCreatedTime = moment(dates[0]).format('YYYY-MM-DD HH:mm:ss')
        this.queryParam.endCreatedTime = moment(dates[1]).format('YYYY-MM-DD HH:mm:ss')
        if (dates.length < 1) {
          delete this.queryParam.startCreatedTime
          delete this.queryParam.endCreatedTime
        }
      }
      const obj = JSON.parse(JSON.stringify(this.queryParam))
      delete obj.dates
      return obj
    },
    /**
     * 清空日志
     */
    sysVisLogDelete() {
      sysVisLogDelete().then(res => {
        if (res.success) {
          this.$message.success('清空成功')
          this.$refs.table.refresh(true)
        } else {
          this.$message.error('清空失败：' + res.message)
        }
      })
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    }
  }
}
</script>
<style lang="less">
.table-operator {
  margin-bottom: 18px;
}

button {
  margin-right: 8px;
}
</style>
