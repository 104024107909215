
import { axios } from '@/utils/request'

/**
 * 分页查询下载列表
 *
 */
export function _downloadpage(parameter) {
  return axios({
    url: '/sysFileInfo/downloadpage',
    method: 'get',
    params: parameter
  })
}

